import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { DataTypePregunta } from '../../../interfaces/utils'
import { AllCardsType } from '../../../data/all-cards'
import { Zodiaco, ZodiacoType } from '../../../data/zodiaco'
import { copyResponse, fotoCartas, getZodiac, isEmpty, selectDiez, setMezcla } from '../../../utils/functions'
import { GetConsult, SaveAllCards, SetConsult } from '../../../store/actions'
import { interpret } from './functions'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import { ButtonNormal } from '../../../components/buttons/button-normal'
import PhotoCards from './photo-cards'
import { ShowCards } from './show-cards'

export const Abundancia = () => {
    const navigate = useNavigate()
    const dispatch: Dispatch<any> = useDispatch()
    const [data, setData] = useState<DataTypePregunta>({
        consultante: {
            name: '',
            date: '',
            zodiac: '',
        },
        consulta: '',
        tipo: '',
    })
    const [copia, setCopia]: any = useState(false)
    const { allCards, user, consult } = useSelector((state: any) => state)
    const [response, setResponse] = useState('')
    const [selectedCards, setSelectedCards] = useState<AllCardsType[]>([])
    const [showResponse, setShowResponse] = useState<boolean>(false)
    const [status, setStatus] = useState({
        isDone: false,
        mezcladas: false,
        repartidas: false,
        isConsulting: false,
        isInterpret: false,
    })
    const zodiaco: ZodiacoType[] = Zodiaco

    // SETEA LOS ESTADOS PARA REALIZAR UNA NUEVA CONSULTA CON EL MISMO CLIENTE
    const nuevaPregunta = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            ...data,
            consulta: '',
        })
        setShowResponse(false)
        setSelectedCards([])
    }

    // LIMPIA LOS ESTADOS PARA ATENDER UN NUEVO CLIENTE
    const nuevoCliente = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            consultante: {
                name: '',
                date: '',
                zodiac: '',
            },
            consulta: '',
            tipo: '',
        })
        setShowResponse(false)
        setSelectedCards([])
    }

    // MANEJA LOS ESTADOS
    const handleStatus = (name: string, value: boolean): void => {
        setStatus({
            ...status,
            [name]: value,
        })
    }

    // RECIBE UNA FECHA Y DEVUELVE EL SIGNO ZODIACAL
    const handleZodiacConsultant = (date: string): void => {
        let zodiac = getZodiac(date)
        setData({
            ...data,
            consultante: {
                ...data.consultante,
                zodiac,
                date,
            },
        })
    }

    // MANEJA LOS INPUTS DE LOS DATOS DEL CONSULTANTE
    const handleInputConsultant = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'date' && parseInt(event.target.value.slice(0, 4)) > 1900) {
            handleZodiacConsultant(event.target.value)
        } else {
            setData({
                ...data,
                consultante: {
                    ...data.consultante,
                    [event.target.name]: event.target.value,
                },
            })
        }
    }

    // MANEJA EL TEXTAREA DE LA CONSULTA
    const handleConsult = (
        event: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>
    ): void => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        })
    }

    // MEZCLA LAS CARTAS
    const mezcla = () => {
        const mezcladas = setMezcla(allCards)
        dispatch(SaveAllCards(mezcladas))
        handleStatus('mezcladas', true)
    }

    // PREPARA LA INFORMACION Y DISPARA LA CONSULTA A LA IA
    const interpretar = () => {
        handleStatus('isConsulting', true)
        const info = interpret(selectedCards, data, user.username, 'abundancia')
        //console.log('info:', info)
        if (data.tipo === '1') {
            dispatch(SetConsult(info))
        } else {
            dispatch(GetConsult(info))
        }
    }

    useEffect(() => {
        if (status.mezcladas && !status.repartidas) {
            setSelectedCards(selectDiez(allCards))
            handleStatus('repartidas', true)
        }
    }, [allCards, status])

    useEffect(() => {
        if (!isEmpty(consult)) {
            setStatus({
                ...status,
                isConsulting: false,
                isInterpret: true,
            })
            setResponse(consult.response)
            setShowResponse(true)
        }
    }, [consult])

    useEffect(() => {
        if (Object.entries(user).length === 0) {
            console.log('entré aca')
            navigate('/')
        }
        setStatus({
            isConsulting: false,
            isInterpret: false,
            isDone: false,
            repartidas: false,
            mezcladas: false,
        })
        setShowResponse(false)
        setResponse('')
        setSelectedCards([])
    }, [])

    useEffect(() => {
        if (Object.entries(user).length === 0) {
            navigate('/')
        }
    }, [user])

    // MUESTRA UNA DERSCRIPCION DEL SIGNO DEL ZODIACO DEL CONSULTANTE
    useEffect(() => {
        if (showResponse) {
            let signoConsultante = document.getElementById('consultante')
            if (signoConsultante && data.consultante.zodiac.length > 0) {
                let description = zodiaco.filter((signo) => signo.name === data.consultante.zodiac)
                signoConsultante.innerHTML = description[0].description
            }
        }
    }, [showResponse, data, zodiaco])

    // COPIA LA IMAGEN DE LAS CARTAS
    useEffect(() => {
        if (copia) {
            let element = document.getElementById('photo')
            if (element) {
                fotoCartas(element, data.consultante.name)
                setCopia(false)
            }
        }
    }, [copia])

    const copiar = () => {
        localStorage.setItem('client', JSON.stringify(data.consultante))
    }

    const pegar = () => {
        const cliente = localStorage.getItem('client')
        if (cliente) {
            const clienteObj = JSON.parse(cliente)
            console.log(clienteObj)
            setData({
                ...data,
                consultante: {
                    name: clienteObj.name,
                    date: clienteObj.date,
                    zodiac: clienteObj.zodiac,
                },
            })
        }
    }

    return (
        <div className="relative w-full h-full min-h-screen bg-no-repeat bg-cover bg-abundancia mini:px-2 md:px-4">
            <p className="w-full py-5 italic font-bold text-center text-white mini:text-xl xl:text-4xl drop-shadow-lg">
                Tirada de la Abundancia y Prosperidad
            </p>
            <div className="flex w-full mini:flex-col xl:flex-row">
                {/* left */}
                <div className="flex flex-col h-full gap-3 rounded-lg mini:w-full xl:w-1/2 bg-violetLight mini:px-2 mini:py-5 md:p-5 shadow-card-horizontal">
                    <div className="flex gap-3 mini:flex-col md:flex-row">
                        <input
                            name="name"
                            type="text"
                            value={data.consultante.name}
                            onChange={(e) => handleInputConsultant(e)}
                            placeholder="Nombre del Consultante"
                            className="h-10 pl-3 text-base text-gray-400 rounded-lg mini:w-full md:w-1/2 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            autoFocus={true}
                        />
                        <div className="flex gap-3">
                            <input
                                type="date"
                                name="date"
                                value={data.consultante.date}
                                onChange={(e) => handleInputConsultant(e)}
                                placeholder="Nacimiento"
                                className="w-1/2 pl-3 text-base text-gray-400 rounded-lg pr-1lh-10 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            />
                            <input
                                type="text"
                                name="zodiac"
                                value={data.consultante.zodiac}
                                readOnly={true}
                                className="w-1/2 h-10 px-3 text-base text-gray-400 rounded-lg disabled:bg-white"
                                disabled={true}
                            />
                        </div>
                        <div className="flex items-center gap-1 text-white">
                            <button
                                className="p-2 rounded-full bg-violetDark hover:cursor-pointer disabled:bg-slate-400 disabled:text-slate-300 disabled:cursor-not-allowed"
                                onClick={() => copiar()}
                                title="Copiar"
                                disabled={data.consultante.name.length === 0 || data.consultante.zodiac.length === 0}
                            >
                                <ContentCopyIcon />
                            </button>
                            <button
                                className="p-2 rounded-full bg-violetDark hover:cursor-pointer"
                                onClick={() => pegar()}
                                title="Pegar"
                            >
                                <ContentPasteIcon />
                            </button>
                        </div>
                    </div>
                    <div>
                        <textarea
                            name="consulta"
                            value={data.consulta}
                            className="p-3 h-[40vh] rounded-lg textr-base text-gray-400 w-full resize-none"
                            placeholder="Acá pueden poner la inquietud del consultante referido a desbloquear la energía del dinero, mejorar la estabilidad financiera y atraer la prosperidad, o dejarlo en blanco"
                            onChange={(e) => handleConsult(e)}
                        />
                    </div>
                    <div className="flex flex-wrap justify-center gap-3 disabled:hidden">
                        <ButtonNormal
                            caption="Hecho"
                            onClick={() => handleStatus('isDone', true)}
                            disabled={
                                status.isDone ||
                                data.consultante.name.length === 0 ||
                                data.consultante.date.length === 0
                            }
                        />
                        <ButtonNormal
                            caption="Repartir"
                            onClick={() => mezcla()}
                            disabled={!status.isDone || status.repartidas}
                        />
                        <ButtonNormal
                            caption="Editar"
                            onClick={() => handleStatus('isDone', false)}
                            disabled={!status.isDone || status.repartidas}
                        />
                        <ButtonNormal
                            caption="Hacer foto de cartas"
                            onClick={() => setCopia(true)}
                            disabled={!status.repartidas || status.isConsulting}
                        />
                        <ButtonNormal
                            caption={status.isConsulting ? 'Interpretando' : 'Interpretar'}
                            onClick={() => interpretar()}
                            disabled={!status.repartidas || status.isInterpret}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption={status.isConsulting ? 'Reinterpretando' : 'Reinterpretar'}
                            onClick={() => interpretar()}
                            disabled={!status.isInterpret}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption="Ver Respuesta"
                            onClick={() => setShowResponse(true)}
                            disabled={response.length === 0}
                        />
                        <ButtonNormal
                            caption="Nueva Tirada"
                            onClick={() => nuevaPregunta()}
                            disabled={!status.isInterpret}
                        />
                        <ButtonNormal
                            caption="Nuevo Cliente"
                            onClick={() => nuevoCliente()}
                            disabled={!status.isInterpret}
                        />
                    </div>
                </div>
                {/* right */}
                {selectedCards.length > 0 ? (
                    <div className="relative mini:w-full xl:w-1/2">
                        <ShowCards selectedCards={selectedCards} />
                    </div>
                ) : null}
                {selectedCards.length > 0 ? (
                    <div
                        className={`absolute w-fit h-fit top-[16vh] mini:left-[3vw] xl:left-[20vw] bg-tapiz ${
                            copia ? 'block' : 'hidden'
                        }`}
                        id="photo"
                    >
                        <PhotoCards cards={selectedCards} />
                    </div>
                ) : null}
                {showResponse ? (
                    <div className="absolute mini:w-[96vw] md:w-[90vw] h-[90vh] top-[2%] mini:left-[2vw] md:left-[5%] bg-zinc-200 shadow-2xl flex flex-row gap-5 rounded-xl mini:p-2 md:p-5">
                        <div className="flex flex-col gap-3 mini:w-full xl:w-2/3">
                            <textarea
                                className="w-full h-full p-5 rounded-lg resize-none"
                                value={response}
                                onChange={(e) => setResponse(e.target.value)}
                            />
                            <div className="flex flex-row flex-wrap justify-center gap-1">
                                <ButtonNormal caption="Volver" onClick={() => setShowResponse(false)} />
                                <ButtonNormal caption="Copiar Respuesta" onClick={() => copyResponse(response)} />
                                <ButtonNormal caption="Nueva Pregunta" onClick={() => nuevaPregunta()} />
                                <ButtonNormal caption="Nuevo Cliente" onClick={() => nuevoCliente()} />
                            </div>
                        </div>
                        <div className="flex flex-col w-1/3 gap-3 mini:hidden xl:block">
                            <p className="font-semibold">Consultante: {data.consultante.zodiac}</p>
                            <div
                                className="w-full p-5 overflow-y-auto text-sm rounded-lg bg-zinc-50"
                                id="consultante"
                            ></div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default Abundancia
