import { Dispatch } from 'redux'
import { AllCards, AllCardsType } from '../../data/all-cards'
import {
    CLOSE_SESSION,
    ERROR,
    ERROR_LOGIN,
    GET_ALL_CARDS,
    GET_ALL_CONSULTS,
    GET_CLIENTS,
    GET_SESSIONS,
    GET_USERS,
    LOGIN,
    LOGOUT,
    POSITION_CARD,
    RESET_CLIENT,
    RESET_CONSULT,
    RESET_ERROR,
    SAVE_ALL_CARDS,
    SAVE_CLIENT,
    SAVE_CONSULT,
    SELECT_CARD,
    SET_CLIENT,
    SET_CONTROL,
} from '../constants'
import axios from 'axios'
import { Configuration, OpenAIApi } from 'openai'
import Swal from 'sweetalert2'
import { deleteSession, getActiveSessions, getDeviceFingerprint } from '../../utils/functions'
import moment, { Moment } from 'moment'

const apiKey = process.env.REACT_APP_OPENAI_API_KEY

const config = new Configuration({
    apiKey,
})
const openai = new OpenAIApi(config)

const headers = {
    'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID,
    'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY,
    'X-Parse-Revocable-Session': '1',
    'Content-Type': 'application/json',
}

export const GetAllCards = () => (dispatch: Dispatch) => {
    let response = AllCards
    dispatch({
        type: GET_ALL_CARDS,
        payload: response,
    })
}

export const SaveAllCards = (payload: AllCardsType[]) => (dispatch: Dispatch) => {
    dispatch({
        type: SAVE_ALL_CARDS,
        payload: payload,
    })
}

export const UserLogin = (payload: any) => async (dispatch: Dispatch) => {
    try {
        const deviceId = await getDeviceFingerprint()

        const response = await axios.get(
            `https://parseapi.back4app.com/login?username=${payload.username}&password=${payload.password}`,
            {
                headers: {
                    'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID,
                    'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY,
                    'X-Parse-Installation-Id': deviceId,
                    'Content-Type': 'application/json',
                },
            }
        )

        const sesiones = await getActiveSessions(response.data.objectId)

        console.log('Sesiones:', sesiones)

        if (sesiones.length > response.data.sesiones) {
            /* Swal.fire(
                'Sesiones Excedidas',
                'Se ha excedido en la cantidad de Sesiones abiertas. Cierre una sesión para ingresar aquí',
                'error'
            )
            deleteSession(sesiones[sesiones.length - 1].objectId)
            dispatch({
                type: LOGOUT,
                paylaod: null,
            }) */
            deleteSession(sesiones[0].objectId)
            Swal.fire({
                title: 'Sesiones Excedidas',
                text: 'Ha excedido su límite de sesiones, he cerrado su sesión más antigua.',
                timer: 2000,
                icon: 'warning',
                timerProgressBar: true,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            })
        }
        dispatch({
            type: LOGIN,
            payload: response.data,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR_LOGIN,
            payload: error,
        })
    }
}

export const CheckUser = (sessionId: string) => async (dispatch: Dispatch) => {
    try {
        const response = await fetch('https://parseapi.back4app.com/users/me', {
            method: 'GET',
            headers: {
                'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID!,
                'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY!,
                'X-Parse-Session-Token': sessionId,
                'Content-Type': 'application/json',
            },
        })

        const data = await response.json()

        if (response.ok) {
            dispatch({
                type: LOGIN,
                payload: data,
            })
        } else {
            localStorage.removeItem('user')
            dispatch({
                type: LOGOUT,
                payload: null,
            })
        }
    } catch (error: any) {
        localStorage.removeItem('user')
        dispatch({
            type: LOGOUT,
            payload: null,
        })
    }
}

export const ResetError = () => (dispatch: Dispatch) => {
    dispatch({
        type: RESET_ERROR,
        payload: null,
    })
}

export const SetUser = (payload: any) => (dispatch: Dispatch) => {
    dispatch({
        type: LOGIN,
        payload: payload,
    })
}

export const GetAllConsults = (pageSize: number, skip: number) => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get(
            `https://parseapi.back4app.com/classes/consults?order=-createdAt&limit=${pageSize}&skip=${skip}`,
            { headers }
        )
        dispatch({
            type: GET_ALL_CONSULTS,
            payload: response.data.results,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const GetConsultsByUser = (username: string, pageSize: number, skip: number) => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get(
            `https://parseapi.back4app.com/classes/consults?order=-createdAt&limit=${pageSize}&skip=${skip}&where={"user":"${username}"}`,
            { headers }
        )

        dispatch({
            type: GET_ALL_CONSULTS,
            payload: response.data.results,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const Logout = (sessionId: string) => async (dispatch: Dispatch) => {
    try {
        const response = await fetch('https://parseapi.back4app.com/logout', {
            method: 'POST',
            headers: {
                'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID!,
                'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY!,
                'X-Parse-Session-Token': sessionId, // Necesario para invalidar la sesión
                'Content-Type': 'application/json',
            },
        })

        if (response.ok) {
            localStorage.removeItem('user') // Eliminar user local
            dispatch({
                type: LOGOUT,
                payload: null,
            })
        } else {
            dispatch({
                type: ERROR,
                payload: await response.json(),
            })
        }
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const GetConsult = (value: any) => async (dispatch: Dispatch) => {
    try {
        let modelIA
        if (value.type === 'general') {
            modelIA = 'gpt-3.5-turbo'
        } else {
            modelIA = 'gpt-4o'
        }

        const response = await openai.createChatCompletion({
            model: modelIA, //gtp-3.5-turbo
            messages: [
                {
                    role: 'system',
                    content:
                        'Eres una experta tarotista y astróloga esotérica, con un profundo conocimiento sobre energía, atracción, manifestación y Registros Akáshicos, intuitiva y muy cariñosa que combina sabiduría ancestral con empatía moderna y estas realizando una tirada de cartas para el tarot o tirada de monedas para el I-Ching y tu misión es buscar respuestas concretas a lo que tu consultante está consultando. También puedes realizar análisis de compatibilidad de parejas. No saludes ni te despidas en las respuestas. No te refieras al consultante en tercera persona. ',
                },
                { role: 'user', content: value.consult },
            ],
            top_p: 1,
        })

        let resp: any = ''
        if (value.type === 'general') {
            response.data.choices.forEach((element) => {
                resp += element.message?.content
            })
        } else {
            resp = response.data.choices[0].message?.content
        }

        // const resp = response.data.choices[0].message?.content

        const data = {
            clientName: value.clientName,
            clientAnswer: value.clientAnswer,
            type: value.type,
            cards: value.cards,
            response: resp,
            user: value.user,
            consult: value.consult,
        }

        await axios.post('https://parseapi.back4app.com/classes/consults', data, {
            headers,
        })

        dispatch({
            type: SAVE_CONSULT,
            payload: data,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const SetConsult = (value: any) => async (dispatch: Dispatch) => {
    const data = {
        clientName: value.clientName,
        clientAnswer: value.clientAnswer,
        type: value.type,
        cards: value.cards,
        response: value.consult,
        user: value.user,
        consult: value.consult,
    }

    await axios.post('https://parseapi.back4app.com/classes/consults', data, {
        headers,
    })

    dispatch({
        type: SAVE_CONSULT,
        payload: data,
    })
}

export const ResetConsult = () => (dispatch: Dispatch) => {
    dispatch({
        type: RESET_CONSULT,
        payload: null,
    })
}

export const SaveClient = (value: any) => async (dispatch: Dispatch) => {
    try {
        console.log('value:', value)
        const arreglo = Array(10)
        arreglo.fill({ video: false, audio: false, indications: false, extra: false, razones: false })
        let data: any = {
            name: value.name,
            birthday: value.date,
            phone: value.phone,
            email: value.email,
            obs: value.obs,
            status: 'active',
            control: arreglo,
        }

        const response = await axios.post('https://parseapi.back4app.com/classes/clients', data, { headers })
        data.objectId = response.data.objectId
        Swal.fire('Cliente Guardado', 'El cliente ha sido agregado a la base de datos', 'success')

        dispatch({
            type: SAVE_CLIENT,
            payload: data,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const ResetClient = () => async (dispatch: Dispatch) => {
    dispatch({
        type: RESET_CLIENT,
        payload: null,
    })
}

export const GetClients = () => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get('https://parseapi.back4app.com/classes/clients', { headers })

        dispatch({
            type: GET_CLIENTS,
            payload: response.data.results,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const SetClient = (value: any) => (dispatch: Dispatch) => {
    dispatch({
        type: SET_CLIENT,
        payload: value,
    })
}

export const SetControl =
    (clientId: string, arreglo: any, position: number, item: string, value: boolean) => async (dispatch: Dispatch) => {
        try {
            arreglo[position][item] = value
            await axios.put(
                'https://parseapi.back4app.com/classes/clients/' + clientId,
                { control: arreglo },
                { headers }
            )

            dispatch({
                type: SET_CONTROL,
                payload: arreglo,
            })
        } catch (error: any) {
            dispatch({
                type: ERROR,
                payload: error.message,
            })
        }
    }

export const GetUsers = () => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get(`https://parseapi.back4app.com/classes/_User?order=username`, { headers })

        dispatch({
            type: GET_USERS,
            payload: response.data.results,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const GetSessions = () => async (dispatch: Dispatch) => {
    try {
        const response = await axios.get('https://parseapi.back4app.com/classes/_Session', {
            headers: {
                'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID!,
                'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY!,
                'X-Parse-Master-Key': process.env.REACT_APP_BACK4APP_MASTER_KEY!,
                'Content-Type': 'application/json',
            },
        })

        dispatch({
            type: GET_SESSIONS,
            payload: response.data.results,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const CloseSession = (sessionId: string) => async (dispatch: Dispatch) => {
    try {
        await fetch(`https://parseapi.back4app.com/classes/_Session/${sessionId}`, {
            method: 'DELETE',
            headers: {
                'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID!,
                'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY!,
                'X-Parse-Master-Key': process.env.REACT_APP_BACK4APP_MASTER_KEY!,
                'Content-Type': 'application/json',
            },
        })

        dispatch({
            type: CLOSE_SESSION,
            payload: sessionId,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const GetConsultsByDate = (date: Moment) => async (dispatch: Dispatch) => {
    console.log('date de la action:', date)
    try {
        const where = {
            createdAt: {
                $gte: date.startOf('day'),
                $lte: date.endOf('day'),
            },
        }

        console.log('Where:', where)

        const response = await axios.get('https://parseapi.back4app.com/classes/consults', {
            headers,
            params: {
                where: JSON.stringify(where),
                count: 1,
                limit: 1000,
            },
        })

        dispatch({
            type: GET_ALL_CONSULTS,
            paylaod: response.data,
        })
    } catch (error: any) {
        dispatch({
            type: ERROR,
            payload: error.message,
        })
    }
}

export const SelectOneCard = (index: number) => (dispatch: Dispatch) => {
    dispatch({
        type: SELECT_CARD,
        payload: index,
    })
}

export const PositionCard = (index: number) => (dispatch: Dispatch) => {
    console.log('entre a la action')
    dispatch({
        type: POSITION_CARD,
        payload: index,
    })
}
