import { useDispatch, useSelector } from 'react-redux'
import { AllCardsType } from '../../../../data/all-cards'
import { useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { GetAllCards, PositionCard, SelectOneCard } from '../../../../store/actions'
import SyncIcon from '@mui/icons-material/Sync'

export const SelectCards = ({
    cantidad,
    onSelect,
}: {
    cantidad: number
    onSelect: (selected: AllCardsType[]) => void
}) => {
    const dispatch: Dispatch<any> = useDispatch()
    const { allCards } = useSelector((state: any) => state)
    const [selected, setSelected] = useState<AllCardsType[]>([])

    useEffect(() => {
        dispatch(GetAllCards())
    }, [])

    const handleSelect = (index: number) => {
        if (selected.length < cantidad || allCards[index].selected) {
            if (!allCards[index].selected) {
                setSelected([...selected, allCards[index]])
            } else {
                setSelected(selected.filter((element: AllCardsType) => element.id !== allCards[index].id))
            }
            dispatch(SelectOneCard(index))
        }
    }

    const handlePosition = (index: number) => {
        setSelected(
            selected.map((Element: AllCardsType) => {
                if (Element.id === allCards[index].id) {
                    console.log('entro acá')
                    Element.position = allCards[index].position === 'derecha' ? 'invertida' : 'derecha'
                    return Element
                } else {
                    return Element
                }
            })
        )
        //dispatch(PositionCard(index))
    }

    console.log('Selected:', selected)

    return (
        <div className="absolute top-0 left-0 flex flex-wrap justify-center w-full h-full gap-3 p-10 overflow-scroll bg-tapiz">
            {allCards?.map((card: AllCardsType, index: number) => (
                <div className="relative flex flex-col gap-1 group" key={index}>
                    <img
                        src={require('../../../../assets/cards/' + card.url + '/' + card.image)}
                        alt=""
                        className={`w-[7vw] h-auto mini:rounded-sm xl:rounded-[7px] group-hover:brightness-50 group-hover:cursor-pointer duration-300 ${
                            card.position === 'derecha' ? 'rotate-0' : 'rotate-180'
                        }`}
                        onClick={() => handleSelect(index)}
                    />
                    <p className="absolute w-[7vw] left-0 top-1/2 text-sm text-center text-white truncate opacity-0 group-hover:opacity-100 duration-200 pointer-events-none">
                        {card.name}
                    </p>
                    <p
                        className={`absolute top-[2vh] left-0 bg-red-500 text-white text-sm px-3 rounded-e-full ${
                            card.selected ? 'opacity-100' : 'opacity-0'
                        } duration-200`}
                    >
                        Seleccionada
                    </p>
                    {card.selected && (
                        <div
                            className="absolute p-1 bg-blue-400 rounded-full bottom-1 right-1 opacity-90 hover:cursor-pointer"
                            onClick={() => handlePosition(index)}
                        >
                            <SyncIcon fontSize="large" />
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}
