import Swal from 'sweetalert2'
import { AllCardsType } from '../data/all-cards'
import { numerologic, tipologias } from '../data/numerologia'
import copy from 'copy-to-clipboard'
import { hexagramas } from '../data/hexagramas'
import html2canvas from 'html2canvas'
import isMobile from 'ismobilejs'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export const randomNumberInRange = (min: number, max: number): number => {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export const isEmpty = (value: any): boolean => {
    return (
        value === null || // Verifica si es null
        value === undefined || // Verifica si es undefined
        value === '' || // Verifica si es una cadena vacía
        value === 0 || // Verifica si es cero
        (Array.isArray(value) && value.length === 0) || // Verifica si es un array vacío
        (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) // Objeto vacío
    )
}

export const setMezcla = (cards: AllCardsType[]): AllCardsType[] => {
    console.log('recibido en setMezcla:', cards)
    let mezcladas = []
    for (let i = 0; i < 78; i++) {
        let pos = randomNumberInRange(0, cards.length - 1)
        let position = randomNumberInRange(1, 100) < 75 ? 'derecha' : 'invertida'
        cards[pos].position = position
        mezcladas.push(cards.splice(pos, 1)[0])
    }
    return mezcladas
}

export const selectFive = (cards: AllCardsType[]): AllCardsType[] => {
    for (let i = 1; i < 6; i++) {
        let select = true
        while (select) {
            let position = randomNumberInRange(0, 77)
            if (cards[position].selected === false) {
                cards[position].selected = true
                cards[position].place = i
                select = false
            }
        }
    }
    let selected: AllCardsType[] = cards.filter((card) => card.selected === true)
    cards.forEach((card) => {
        card.selected = false
        card.place = 0
    })
    return selected
}

export const selectTen = (cards: AllCardsType[]): AllCardsType[] => {
    for (let i = 1; i < 11; i++) {
        let select = true
        while (select) {
            let position = randomNumberInRange(0, 77)
            if (cards[position].selected === false) {
                cards[position].selected = true
                cards[position].place = i
                select = false
            }
        }
    }
    return cards
}

export const selectDiez = (cards: AllCardsType[]): AllCardsType[] => {
    for (let i = 1; i < 11; i++) {
        let select = true
        while (select) {
            let position = randomNumberInRange(0, 77)
            if (cards[position].selected === false) {
                cards[position].selected = true
                cards[position].place = i
                select = false
            }
        }
    }
    let selected: AllCardsType[] = cards.filter((card) => card.selected === true)
    cards.forEach((card) => {
        card.selected = false
        card.place = 0
    })
    return selected
}

export const selectQuince = (cards: AllCardsType[]): AllCardsType[] => {
    for (let i = 1; i < 16; i++) {
        let select = true
        while (select) {
            let position = randomNumberInRange(0, 77)
            if (cards[position].selected === false) {
                cards[position].selected = true
                cards[position].place = i
                select = false
            }
        }
    }
    let selected: AllCardsType[] = cards.filter((card) => card.selected === true)
    cards.forEach((card) => {
        card.selected = false
        card.place = 0
    })
    return selected
}

export const selectVeinte = (cards: AllCardsType[]): AllCardsType[] => {
    for (let i = 1; i < 21; i++) {
        let select = true
        while (select) {
            let position = randomNumberInRange(0, 77)
            if (cards[position].selected === false) {
                cards[position].selected = true
                cards[position].place = i
                select = false
            }
        }
    }
    let selected: AllCardsType[] = cards.filter((card) => card.selected === true)
    cards.forEach((card) => {
        card.selected = false
        card.place = 0
    })
    return selected
}

export const selectCatorce = (cards: AllCardsType[]): AllCardsType[] => {
    for (let i = 1; i < 15; i++) {
        let select = true
        while (select) {
            let position = randomNumberInRange(0, 77)
            if (cards[position].selected === false) {
                cards[position].selected = true
                cards[position].place = i
                select = false
            }
        }
    }
    let selected: AllCardsType[] = cards.filter((card) => card.selected === true)
    cards.forEach((card) => {
        card.selected = false
        card.place = 0
    })
    return selected
}

export const selectSeven = (cards: AllCardsType[]): AllCardsType[] => {
    for (let i = 1; i < 8; i++) {
        let select = true
        while (select) {
            let position = randomNumberInRange(0, 77)
            if (cards[position].selected === false) {
                cards[position].selected = true
                cards[position].place = i
                select = false
            }
        }
    }
    let selected: AllCardsType[] = cards.filter((card) => card.selected === true)
    cards.forEach((card) => {
        card.selected = false
        card.place = 0
    })
    return selected
}

export const selectVeinticinco = (cards: AllCardsType[]): AllCardsType[] => {
    for (let i = 1; i < 26; i++) {
        let select = true
        while (select) {
            let position = randomNumberInRange(0, 77)
            if (cards[position].selected === false) {
                cards[position].selected = true
                cards[position].place = i
                select = false
            }
        }
    }
    let selected: AllCardsType[] = cards.filter((card) => card.selected === true)
    cards.forEach((card) => {
        card.selected = false
        card.place = 0
    })
    return selected
}

export const sortCards = (cards: AllCardsType[]): AllCardsType[] => {
    let selected: AllCardsType[] = cards.filter((card: AllCardsType) => card.selected)
    selected.sort((a: AllCardsType, b: AllCardsType) => {
        if (a.place > b.place) {
            return 1
        } else if (a.place < b.place) {
            return -1
        } else {
            return 0
        }
    })
    return selected
}

export const resetCards = (cards: AllCardsType[]): AllCardsType[] => {
    return cards.map((card) => {
        if (card.selected) {
            return {
                ...card,
                selected: false,
                place: 0,
                casa: 0,
            }
        } else {
            return card
        }
    })
}

export const copyResponse = async (response: string): Promise<void> => {
    try {
        //await navigator.clipboard.writeText(response)
        copy(response)
        Swal.fire('Respuesta copiada', 'Texto enviado al portapapeles', 'success')
    } catch (error: any) {
        Swal.fire('Error', error, 'error')
    }
}

export const resumen = (data: string): string => {
    return data.length > 150 ? data.slice(0, 150) + ' ...' : data
}

export const getCard = (allCards: AllCardsType[], card: string): AllCardsType => {
    let position = allCards.findIndex((elem: AllCardsType) => elem.name === card)
    return allCards[position]
}

export const getEdad = (dateString: string): string => {
    let hoy = new Date()
    let fechaNacimiento = new Date(dateString)
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
    if (diferenciaMeses < 0 || (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
        edad--
    }
    return edad.toString()
}

export const getValorLetra = (letra: string): string => {
    const letras: { [key: string]: number } = {
        a: 1,
        b: 2,
        c: 3,
        d: 4,
        e: 5,
        f: 6,
        g: 7,
        h: 8,
        i: 9,
        j: 1,
        k: 2,
        l: 3,
        m: 4,
        n: 5,
        ñ: 15,
        o: 6,
        p: 7,
        q: 8,
        r: 9,
        s: 1,
        t: 2,
        u: 3,
        v: 4,
        w: 5,
        x: 6,
        y: 7,
        z: 8,
    }

    return letras[letra].toString()
}

export const getValorVocal = (vocal: string): string => {
    const vocales: { [key: string]: number } = {
        a: 1,
        e: 5,
        i: 9,
        o: 6,
        u: 3,
        y: 7,
    }
    return vocales[vocal].toString()
}

export const getValorConsonante = (consonante: string): string => {
    const consonantes: { [key: string]: number } = {
        b: 2,
        c: 3,
        d: 4,
        f: 6,
        g: 7,
        h: 8,
        j: 1,
        k: 2,
        l: 3,
        m: 4,
        n: 5,
        ñ: 5,
        p: 7,
        q: 8,
        r: 9,
        s: 1,
        t: 2,
        v: 4,
        w: 5,
        x: 6,
        z: 8,
    }
    return consonantes[consonante].toString()
}

export const isVocal = (letra: string): boolean => {
    return ['a', 'e', 'i', 'o', 'u', 'y'].includes(letra.toLowerCase())
}

export const getDescription = (data: any): string[] => {
    console.log(data)
    let parrafos = []
    let control = [true, false, false, false]

    parrafos.push(`Análisis Numerológico de ${data.consultante}`)
    parrafos.push(`Número de Vida ${data.vida}: ${numerologic['vida']}`)
    if (data.vida === data.personalidad) {
        control[1] = true
        parrafos.push(`Número de Personalidad ${data.personalidad}: ${numerologic['personalidad']}`)
    }
    if (data.vida === data.expresion) {
        control[2] = true
        parrafos.push(`Número de Expresion ${data.expresion}: ${numerologic['expresion']}`)
    }
    if (data.vida === data.alma) {
        control[3] = true
        parrafos.push(`Número del Alma ${data.alma}: ${numerologic['alma']}`)
    }
    tipologias[data.vida].split('/n').map((elem: string) => parrafos.push(elem))

    if (!control[1]) {
        parrafos.push(`Número de Personalidad ${data.personalidad}: ${numerologic['personalidad']}`)
        control[1] = true
        if (!control[2] && data.personalidad === data.expresion) {
            parrafos.push(`Número de Expresion ${data.expresion}: ${numerologic['expresion']}`)
            control[2] = true
        }
        if (!control[3] && data.pesonalidad === data.alma) {
            parrafos.push(`Número del Alma ${data.alma}: ${numerologic['alma']}`)
            control[3] = true
        }
        tipologias[data.personalidad].split('/n').map((elem: string) => parrafos.push(elem))
    }

    if (!control[2]) {
        parrafos.push(`Número de Expresion ${data.expresion}: ${numerologic['expresion']}`)
        control[2] = true
        if (!control[3] && data.expresion === data.alma) {
            parrafos.push(`Número del Alma ${data.alma}: ${numerologic['alma']}`)
            control[3] = true
        }
        tipologias[data.expresion].split('/n').map((elem: string) => parrafos.push(elem))
    }

    if (!control[3]) {
        parrafos.push(`Número del Alma ${data.alma}: ${numerologic['alma']}`)
        tipologias[data.alma].split('/n').map((elem: string) => parrafos.push(elem))
    }
    return parrafos
}

export const getZodiac = (date: string): string => {
    console.log('Fecha:', date)
    const partesFecha = date.split('-')
    if (partesFecha.length !== 3) {
        throw new Error("Formato de fecha inválido. Utiliza 'YYYY-MM-DD'.")
    }

    const dia = parseInt(partesFecha[2])
    const mes = parseInt(partesFecha[1])

    if ((mes === 3 && dia >= 21) || (mes === 4 && dia <= 20)) {
        return 'Aries'
    } else if ((mes === 4 && dia >= 21) || (mes === 5 && dia <= 20)) {
        return 'Tauro'
    } else if ((mes === 5 && dia >= 21) || (mes === 6 && dia <= 20)) {
        return 'Géminis'
    } else if ((mes === 6 && dia >= 21) || (mes === 7 && dia <= 22)) {
        return 'Cáncer'
    } else if ((mes === 7 && dia >= 23) || (mes === 8 && dia <= 22)) {
        return 'Leo'
    } else if ((mes === 8 && dia >= 23) || (mes === 9 && dia <= 22)) {
        return 'Virgo'
    } else if ((mes === 9 && dia >= 23) || (mes === 10 && dia <= 22)) {
        return 'Libra'
    } else if ((mes === 10 && dia >= 23) || (mes === 11 && dia <= 21)) {
        return 'Escorpio'
    } else if ((mes === 11 && dia >= 22) || (mes === 12 && dia <= 21)) {
        return 'Sagitario'
    } else if ((mes === 12 && dia >= 22) || (mes === 1 && dia <= 19)) {
        return 'Capricornio'
    } else if ((mes === 1 && dia >= 20) || (mes === 2 && dia <= 18)) {
        return 'Acuario'
    } else if ((mes === 2 && dia >= 19) || (mes === 3 && dia <= 20)) {
        return 'Piscis'
    } else {
        throw new Error('Fecha fuera de rango.')
    }
}

export const GetPersonalidad = (fecha: string) => {
    let total = 0
    for (let i = 0; i < fecha.length; i++) {
        if (parseInt(fecha[i]) >= 0 && parseInt(fecha[i]) <= 9) {
            total += parseInt(fecha[i])
        }
    }
    while (total > 9 && total !== 11 && total !== 22 && total !== 33) {
        let total2 = 0
        let paso = total.toString()
        for (let i = 0; i < paso.length; i++) {
            total2 += parseInt(paso[i])
        }
        total = total2
    }
    return total.toString()
}

export const GetLinea = (): number => {
    let moneda1 = randomNumberInRange(0, 1) + 2
    let moneda2 = randomNumberInRange(0, 1) + 2
    let moneda3 = randomNumberInRange(0, 1) + 2
    return moneda1 + moneda2 + moneda3
}

export const GetHexagrama = () => {
    let hexag = []
    let diagram = ''
    for (let i = 0; i < 6; i++) {
        let num = GetLinea()
        hexag.push(num)
        if (num % 2 === 0) {
            diagram += '0'
        } else {
            diagram += '1'
        }
    }
    console.log('Diagrama principal', diagram)
    let position = hexagramas.findIndex((elem) => elem.diagrama === diagram)
    hexag.push(hexagramas[position].nombre)
    hexag.push(hexagramas[position].numero)
    return hexag
}

export const GetComplemento = (hexag: any): any => {
    let control = false
    let diagram = ''
    for (let i = 0; i < 6; i++) {
        if (hexag[i] === 9) {
            control = true
            diagram += '0'
        } else if (hexag[i] === 6) {
            control = true
            diagram += '1'
        } else {
            if (hexag[i] % 2 === 0) {
                diagram += '0'
            } else {
                diagram += '1'
            }
        }
    }
    console.log('Diagrama del complemento:', diagram)
    if (control) {
        let position = hexagramas.findIndex((elem) => elem.diagrama === diagram)
        return hexagramas[position]
    } else {
        return null
    }
}

export const fotoCartas = (element: HTMLElement, name: string) => {
    html2canvas(element, { allowTaint: true, useCORS: true }).then((canvas: any) => {
        canvas.toBlob(async (blob: Blob) => {
            if (isMobile(window.navigator).apple.device) {
                var a = document.createElement('a')
                a.style.display = 'none'
                document.body.appendChild(a)
                const cardsImage = new File([blob], Date.now().toLocaleString() + '.png', {
                    type: 'image/png',
                })
                var url = window.URL.createObjectURL(cardsImage)
                a.href = url
                a.download = name + '.png'
                a.click()
                window.URL.revokeObjectURL(url)
            } else {
                try {
                    await navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })])

                    Swal.fire('Cartas copiadas', 'Imagen enviada al portapapeles', 'success')
                } catch (error: any) {
                    Swal.fire('Error', error.message, 'error')
                }
            }
        })
    })
}

export const CopyVideo = async (myVideo: any) => {
    try {
        const response = await fetch(myVideo)
        const videoBlob = await response.blob()
        await navigator.clipboard.write([new ClipboardItem({ 'video/mp4': videoBlob })])
        Swal.fire('Video copiado', 'Video enviado al portapapeles', 'success')
    } catch (error: any) {
        Swal.fire('Error', error.message, 'error')
    }
}

export const shareMedia = async (myVideo: any, title: string) => {
    if (navigator.share) {
        try {
            await navigator.share({
                files: [myVideo],
                title,
                text: title,
            })
        } catch (error: any) {
            Swal.fire('Error', error.message, 'error')
        }
    } else {
        Swal.fire('Error', 'Compartir no está soportado en tu navegador', 'error')
    }
}

export const getActiveSessions = async (userId: string): Promise<any[]> => {
    try {
        const response = await fetch(
            `https://parseapi.back4app.com/classes/_Session?where={"user":{"__type":"Pointer","className":"_User","objectId":"${userId}"}}&order=createdAt`,
            {
                method: 'GET',
                headers: {
                    'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID!,
                    'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY!,
                    'X-Parse-Master-Key': process.env.REACT_APP_BACK4APP_MASTER_KEY!, // Necesario para acceder a _Session
                    'Content-Type': 'application/json',
                },
            }
        )

        const data = await response.json()
        return data.results // Devuelve las sesiones activas ordenadas por fecha
    } catch (error: any) {
        console.error('Error al obtener sesiones activas:', error.message)
        return []
    }
}

export const deleteSession = async (sessionId: string) => {
    try {
        const response = await fetch(`https://parseapi.back4app.com/classes/_Session/${sessionId}`, {
            method: 'DELETE',
            headers: {
                'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID!,
                'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY!,
                'X-Parse-Master-Key': process.env.REACT_APP_BACK4APP_MASTER_KEY!,
                'Content-Type': 'application/json',
            },
        })

        if (response.ok) {
            console.log(`Sesión ${sessionId} eliminada.`)
        } else {
            console.error('Error al eliminar la sesión:', await response.json())
        }
    } catch (error: any) {
        console.error('Error en la petición DELETE:', error.message)
    }
}

export const logoutUser = async (sesionId: string): Promise<void> => {
    try {
        const response = await fetch('https://parseapi.back4app.com/logout', {
            method: 'POST',
            headers: {
                'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID!,
                'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY!,
                'X-Parse-Session-Token': sesionId, // Necesario para invalidar la sesión
                'Content-Type': 'application/json',
            },
        })

        if (response.ok) {
            console.log('Sesión cerrada correctamente.')
            localStorage.removeItem('sessionToken') // Eliminar token local
        } else {
            console.error('Error al cerrar sesión:', await response.json())
        }
    } catch (error: any) {
        console.error('Error en la petición de logout:', error.message)
    }
}

export const checkSession = async (user: any): Promise<boolean> => {
    try {
        const response = await fetch('https://parseapi.back4app.com/users/me', {
            method: 'GET',
            headers: {
                'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID!,
                'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY!,
                'X-Parse-Session-Token': user.sessionToken,
                'Content-Type': 'application/json',
            },
        })

        if (response.ok) {
            return true
        } else {
            localStorage.removeItem('user')
            return false
        }
    } catch (error: any) {
        return false
    }
}

export const getTotalConsults = async () => {
    const response = await fetch('https://parseapi.back4app.com/classes/consults?count=1&limit=0', {
        method: 'GET',
        headers: {
            'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID!,
            'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY!,
            'Content-Type': 'application/json',
        },
    })
    const data = await response.json()
    return data.count
}

export const getTotalConsultsByUser = async (userName: string) => {
    const response = await fetch(
        `https://parseapi.back4app.com/classes/consults?count=1&limit=0&where={"user":"${userName}"}`,
        {
            method: 'GET',
            headers: {
                'X-Parse-Application-Id': process.env.REACT_APP_BACK4APP_APPLICATION_ID!,
                'X-Parse-REST-API-Key': process.env.REACT_APP_BACK4APP_REST_API_KEY!,
                'Content-Type': 'application/json',
            },
        }
    )
    const data = await response.json()
    return data.count
}

export const getDeviceFingerprint = async () => {
    const fp = await FingerprintJS.load()
    const result = await fp.get()
    return result.visitorId // Este es el ID único del dispositivo
}
