import React, { useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import { useNavigate } from 'react-router-dom'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { DataTypePregunta } from '../../../../interfaces/utils'
import { AllCardsType } from '../../../../data/all-cards'
import { Zodiaco, ZodiacoType } from '../../../../data/zodiaco'
import { getZodiac } from '../../../../utils/functions'
import { ButtonNormal } from '../../../../components/buttons/button-normal'
import { SelectCards } from '../shared/select-cards'
import LoopIcon from '@mui/icons-material/Loop'

const OneQuestion = () => {
    const navigate = useNavigate()
    const dispatch: Dispatch<any> = useDispatch()
    const [data, setData] = useState<DataTypePregunta>({
        consultante: {
            name: '',
            date: '',
            zodiac: '',
        },
        consulta: '',
        tipo: '',
    })
    const [copia, setCopia]: any = useState(false)
    const { allCards, user, consult } = useSelector((state: any) => state)
    const [response, setResponse] = useState('')
    const [selectedCards, setSelectedCards] = useState<AllCardsType[]>([])
    const [showResponse, setShowResponse] = useState<boolean>(false)
    const [status, setStatus] = useState({
        isDone: false,
        mezcladas: false,
        selecting: false,
        repartidas: false,
        isConsulting: false,
        isInterpret: false,
    })
    const zodiaco: ZodiacoType[] = Zodiaco

    const nuevaPregunta = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            selecting: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            ...data,
            consulta: '',
        })
        setShowResponse(false)
        setSelectedCards([])
    }

    // LIMPIA LOS ESTADOS PARA ATENDER UN NUEVO CLIENTE
    const nuevoCliente = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            selecting: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            consultante: {
                name: '',
                date: '',
                zodiac: '',
            },
            consulta: '',
            tipo: '',
        })
        setShowResponse(false)
        setSelectedCards([])
    }

    // MANEJA LOS ESTADOS
    const handleStatus = (name: string, value: boolean): void => {
        setStatus({
            ...status,
            [name]: value,
        })
    }

    // RECIBE UNA FECHA Y DEVUELVE EL SIGNO ZODIACAL
    const handleZodiacConsultant = (date: string): void => {
        let zodiac = getZodiac(date)
        setData({
            ...data,
            consultante: {
                ...data.consultante,
                zodiac,
                date,
            },
        })
    }

    // MANEJA LOS INPUTS DE LOS DATOS DEL CONSULTANTE
    const handleInputConsultant = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'date' && parseInt(event.target.value.slice(0, 4)) > 1900) {
            handleZodiacConsultant(event.target.value)
        } else {
            setData({
                ...data,
                consultante: {
                    ...data.consultante,
                    [event.target.name]: event.target.value,
                },
            })
        }
    }

    // MANEJA EL TEXTAREA DE LA CONSULTA
    const handleConsult = (
        event: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>
    ): void => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        })
    }

    const copiar = () => {
        localStorage.setItem('client', JSON.stringify(data.consultante))
    }

    const pegar = () => {
        const cliente = localStorage.getItem('client')
        if (cliente) {
            const clienteObj = JSON.parse(cliente)
            console.log(clienteObj)
            setData({
                ...data,
                consultante: {
                    name: clienteObj.name,
                    date: clienteObj.date,
                    zodiac: clienteObj.zodiac,
                },
            })
        }
    }

    const selectingCards = (cards: AllCardsType[]) => {
        setSelectedCards(cards)
        setStatus({
            ...status,
            selecting: false,
            repartidas: true,
        })
    }

    //console.log('selecting:', status.selecting)

    return (
        <div className="relative w-full h-full bg-no-repeat bg-cover min-h-height-main bg-pregunta mini:px-2 md:px-4">
            <p className="w-full py-5 italic font-bold text-center text-white mini:text-xl xl:text-4xl drop-shadow-lg">
                Tirada Manual para Una Pregunta
            </p>
            <div className="flex w-full mini:flex-col xl:flex-row">
                {/* left */}
                <div className="flex flex-col h-full gap-3 rounded-lg mini:w-full xl:w-1/2 bg-violetLight mini:px-2 mini:py-5 md:p-5 shadow-card-horizontal">
                    <div className="flex gap-3 mini:flex-col md:flex-row">
                        <input
                            name="name"
                            type="text"
                            value={data.consultante.name}
                            onChange={(e) => handleInputConsultant(e)}
                            placeholder="Nombre del Consultante"
                            className="h-10 pl-3 text-base text-gray-400 rounded-lg mini:w-full md:w-1/2 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            autoFocus={true}
                        />
                        <div className="flex gap-3">
                            <input
                                type="date"
                                name="date"
                                value={data.consultante.date}
                                onChange={(e) => handleInputConsultant(e)}
                                placeholder="Nacimiento"
                                className="w-1/2 pl-3 text-base text-gray-400 rounded-lg pr-1lh-10 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            />
                            <input
                                type="text"
                                name="zodiac"
                                value={data.consultante.zodiac}
                                readOnly={true}
                                className="w-1/2 h-10 px-3 text-base text-gray-400 rounded-lg disabled:bg-white"
                                disabled={true}
                            />
                        </div>
                        <div className="flex items-center gap-1 text-white">
                            <button
                                className="p-2 rounded-full bg-violetDark hover:cursor-pointer disabled:bg-slate-400 disabled:text-slate-300 disabled:cursor-not-allowed"
                                onClick={() => copiar()}
                                title="Copiar"
                                disabled={data.consultante.name.length === 0 || data.consultante.zodiac.length === 0}
                            >
                                <ContentCopyIcon />
                            </button>
                            <button
                                className="p-2 rounded-full bg-violetDark hover:cursor-pointer"
                                onClick={() => pegar()}
                                title="Pegar"
                            >
                                <ContentPasteIcon />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-1">
                        <div className="flex gap-1">
                            <input type="radio" name="tipo" value={1} id="SN" onChange={(e) => handleConsult(e)} />
                            <label htmlFor="SN">Si/No</label>
                        </div>

                        <div className="flex gap-1">
                            <input type="radio" name="tipo" value={2} id="PPF" onChange={(e) => handleConsult(e)} />
                            <label htmlFor="PPF">Pasado Presente Futuro</label>
                        </div>

                        <div className="flex gap-1">
                            <input type="radio" name="tipo" value={3} id="PICR" onChange={(e) => handleConsult(e)} />
                            <label htmlFor="PICR">Presente Desafíos Influencias Consejo y Resultado</label>
                        </div>
                        <div className="flex gap-1">
                            <input type="radio" name="tipo" value={4} id="auto" onChange={(e) => handleConsult(e)} />
                            <label htmlFor="auto">Automático</label>
                        </div>
                        <div>
                            <textarea
                                name="consulta"
                                value={data.consulta}
                                className="p-3 h-[40vh] rounded-lg textr-base text-gray-400 w-full resize-none"
                                placeholder="Consulta"
                                onChange={(e) => handleConsult(e)}
                            />
                        </div>
                        <div className="flex flex-wrap justify-center gap-3 disabled:hidden">
                            <ButtonNormal
                                caption="Hecho"
                                onClick={() => handleStatus('isDone', true)}
                                disabled={
                                    status.isDone ||
                                    data.consultante.name.length === 0 ||
                                    data.consultante.date.length === 0 ||
                                    data.consulta.length === 0 ||
                                    data.tipo?.length === 0
                                }
                            />
                            <ButtonNormal
                                caption="Elegir Cartas"
                                onClick={() => handleStatus('selecting', true)}
                                disabled={!status.isDone || status.repartidas}
                            />
                            <ButtonNormal
                                caption="Editar"
                                onClick={() => handleStatus('isDone', false)}
                                disabled={!status.isDone || status.repartidas}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {status.selecting && <SelectCards cantidad={5} onSelect={selectingCards} />}
        </div>
    )
}

export default OneQuestion
