import { AllCardsType } from '../../../data/all-cards'
import { DataTypePregunta } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataTypePregunta, user: string, type: string) => {
    //console.log('Recibido en interpret:', data)
    const consult = `Quiero que interpretes una **Tirada de Protección y Energía**  para ${data.consultante}, ${
        data.consulta.length > 0 ? 'que realiza la siguiente consulta: ' + data.consulta + ',' : ''
    } según estas cartas: **Energía actual:** ${cards[0].name}}${
        cards[0].position === 'invertida' ? ' invertida' : ''
    }, **Energía externa influyendo:** ${cards[1].name}}${
        cards[1].position === 'invertida' ? ' invertida' : ''
    }, **Origen del bloqueo o ataque:** ${cards[2].name}${
        cards[2].position === 'invertida' ? ' invertida' : ''
    }, **Punto vulnerable:** ${cards[3].name}${
        cards[3].position === 'invertida' ? ' invertida' : ''
    }, **Cómo protegerse:** ${cards[4].name}${
        cards[4].position === 'invertida' ? ' invertida' : ''
    }, **Aliado espiritual:** ${cards[5].name}${
        cards[5].position === 'invertida' ? ' invertida' : ''
    }, **Resultado si sigue el consejo:** ${cards[6].name}${
        cards[6].position === 'invertida' ? ' invertida' : ''
    }. Explica detalladamente cada carta y su significado dentro de la tirada. Luego, brinda consejos específicos para la protección energética basados en la interpretación. Si detectas alguna energía negativa, sugiere limpiezas, amuletos o rituales adecuados. Mantén un tono profesional y comprensible. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación de cada una de todo el desarrollo de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante en esta tirada.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
