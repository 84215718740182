import Swal from 'sweetalert2'
import { AllCards, AllCardsType } from '../../data/all-cards'
import {
    CLOSE_SESSION,
    ERROR,
    ERROR_LOGIN,
    GET_ALL_CARDS,
    GET_ALL_CONSULTS,
    GET_CLIENTS,
    GET_SESSIONS,
    GET_USERS,
    LOGIN,
    LOGOUT,
    POSITION_CARD,
    RESET_CLIENT,
    RESET_CONSULT,
    RESET_ERROR,
    SAVE_ALL_CARDS,
    SAVE_CLIENT,
    SAVE_CONSULT,
    SELECT_CARD,
    SET_CLIENT,
    SET_CONTROL,
} from '../constants'

interface InitialStateType {
    allCards: AllCardsType[]
    users: []
    user: any
    errorLogin: number
    consults: []
    consult: any
    client: any
    clients: []
    sessions: []
}

const initialState: InitialStateType = {
    allCards: AllCards,
    users: [],
    user: {},
    errorLogin: 0,
    consults: [],
    consult: {},
    client: {},
    clients: [],
    sessions: [],
}

const Reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_ALL_CARDS: {
            return {
                ...state,
                allCards: action.payload,
            }
        }
        case SAVE_ALL_CARDS: {
            return {
                ...state,
                allCards: action.payload,
            }
        }
        case LOGIN: {
            return {
                ...state,
                user: action.payload,
                errorLogin: 0,
            }
        }
        case ERROR: {
            Swal.fire('Error', action.payload, 'error')
            return state
        }
        case ERROR_LOGIN: {
            return {
                ...state,
                errorLogin: state.errorLogin + 1,
            }
        }
        case RESET_ERROR: {
            return {
                ...state,
                errorLogin: 0,
            }
        }
        case LOGOUT: {
            return {
                ...state,
                user: {},
            }
        }
        case SAVE_CONSULT: {
            return {
                ...state,
                consult: action.payload,
            }
        }
        case RESET_CONSULT: {
            return {
                ...state,
                consult: {},
            }
        }
        case GET_ALL_CONSULTS: {
            return {
                ...state,
                consults: action.payload,
            }
        }
        case SAVE_CLIENT: {
            return {
                ...state,
                client: action.payload,
            }
        }
        case RESET_CLIENT: {
            return {
                ...state,
                client: {},
            }
        }
        case GET_CLIENTS: {
            return {
                ...state,
                clients: action.payload,
            }
        }
        case SET_CLIENT: {
            return {
                ...state,
                client: action.payload,
            }
        }
        case SET_CONTROL: {
            return {
                ...state,
                client: {
                    ...state.client,
                    control: action.payload,
                },
            }
        }
        case GET_USERS: {
            return {
                ...state,
                users: action.payload,
            }
        }
        case GET_SESSIONS: {
            return {
                ...state,
                sessions: action.payload,
            }
        }
        case CLOSE_SESSION: {
            console.log(action.payload)
            return {
                ...state,
                sessions: state.sessions.filter((session: any) => session.objectId !== action.payload),
            }
        }
        case SELECT_CARD: {
            return {
                ...state,
                allCards: state.allCards.map((card: AllCardsType, index: number) => {
                    if (index === action.payload) {
                        card.selected = !card.selected
                        return card
                    } else {
                        return card
                    }
                }),
            }
        }
        case POSITION_CARD: {
            return {
                ...state,
                allCards: state.allCards.map((card: AllCardsType, index: number) => {
                    if (index === action.payload) {
                        console.log(card.position)
                        card.position = card.position === 'derecha' ? 'invertida' : 'derecha'
                        return card
                    } else {
                        return card
                    }
                }),
            }
        }
        default: {
            return state
        }
    }
}

export default Reducer
