import { AllCardsType } from '../../../data/all-cards'
import { DataTypePregunta } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataTypePregunta, user: string, type: string) => {
    //console.log('Recibido en interpret:', data)
    const consult = `Quiero que interpretes una **Tirada de Autoconocimiento Profundo** con 10 cartas, ayudando al consultante ${
        data.consultante
    } a descubrir su verdadera esencia y su camino de crecimiento, ${
        data.consulta.length > 0
            ? 'ten en cuenta en la tirada la siguiente consulta que realiza el consultante: ' + data.consulta + ','
            : ''
    } según estas cartas: **¿Quién soy realmente?:** ${cards[0].name}}${
        cards[0].position === 'invertida' ? ' invertida' : ''
    }, **¿Qué imágen proyecto al mundo?:** ${cards[1].name}}${
        cards[1].position === 'invertida' ? ' invertida' : ''
    }, **Mi mayor fortaleza:** ${cards[2].name}${
        cards[2].position === 'invertida' ? ' invertida' : ''
    }, **Mi mayor debilidad o sombra:** ${cards[3].name}${
        cards[3].position === 'invertida' ? ' invertida' : ''
    }, **Patrón de autosabotaje:** ${cards[4].name}${
        cards[4].position === 'invertida' ? ' invertida' : ''
    }, **Mensaje de mi subconsciente:** ${cards[5].name}${
        cards[5].position === 'invertida' ? ' invertida' : ''
    }, **Lección de vida en este momento:** ${cards[6].name}${
        cards[6].position === 'invertida' ? ' invertida' : ''
    } **Cómo alinear mi vida con mi verdadero ser:**: ${cards[7].name}${
        cards[7].position === 'invertida' ? ' invertida' : ''
    } **Consejo espiritual o del universo:** ${cards[8].name}${
        cards[8].position === 'invertida' ? ' invertida' : ''
    } y **Resultado si sigo el camino del autoconocimiento:** ${cards[9].name}${
        cards[9].position === 'invertida' ? ' invertida' : ''
    }. Explica detalladamente cada carta y su significado en la posición donde ha salido. Detecta conexiones entre las cartas y patrones en la lectura. Proporciona consejos prácticos y reflexivos para ayudar al consultante en su camino de autoconocimiento. Si detectas bloqueos emocionales o creencias limitantes, sugiere herramientas de introspección y sanación.Mantén un tono profesional, empático y comprensible. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación de cada una de todo el desarrollo de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante en esta tirada.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
