import React, { useEffect, useState } from 'react'
import styles from './all-consults.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { Link, useNavigate } from 'react-router-dom'
import { getTotalConsults, getTotalConsultsByUser, isEmpty, resumen } from '../../../utils/functions'
import { GetAllConsults, GetConsultsByUser, GetUsers } from '../../../store/actions'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

const AllConsults = (): JSX.Element => {
    const { consults, user, allCards, users } = useSelector((state: any) => state)
    const [isLoading, setIsLoading] = useState(false)
    const [pageSize, setPageSize] = useState<number>(100)
    const [skip, setSkip] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [allRecords, setAllRecords] = useState<number>(0)
    const [filter, setFilter] = useState<string>('all')
    const dispatch: Dispatch<any> = useDispatch()
    const navigate = useNavigate()

    const handleTotalPages = async () => {
        const total = await getTotalConsults()
        setAllRecords(total)
        setTotalPages(Math.ceil(total / pageSize))
    }

    const handleTotalPagesByUser = async (user: string) => {
        const total = await getTotalConsultsByUser(user)
        setAllRecords(total)
        setTotalPages(Math.ceil(total / pageSize))
    }

    const recargar = () => {
        setIsLoading(true)
        if (filter === 'all') {
            dispatch(GetAllConsults(pageSize, 0))
            handleTotalPages()
        } else {
            dispatch(GetConsultsByUser(filter, pageSize, 0))
            handleTotalPagesByUser(filter)
        }
        setSkip(0)
        setCurrentPage(1)
    }

    const back = () => {
        dispatch(GetAllConsults(pageSize, 0))
        handleTotalPages()
        setFilter('all')
        setSkip(0)
    }

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value === 'all') {
            recargar()
        } else {
            setIsLoading(true)
            dispatch(GetConsultsByUser(event.target.value, pageSize, 0))
            setSkip(0)
            setCurrentPage(1)
            handleTotalPagesByUser(event.target.value)
        }
        setFilter(event.target.value)
    }

    useEffect(() => {
        if (!isEmpty(user)) {
            setIsLoading(true)
            dispatch(GetAllConsults(pageSize, skip))
            handleTotalPages()
            dispatch(GetUsers())
        } else {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        setSkip((currentPage - 1) * pageSize)
        setIsLoading(true)
        if (filter === 'all') {
            dispatch(GetAllConsults(pageSize, (currentPage - 1) * pageSize))
            handleTotalPages()
        } else {
            dispatch(GetConsultsByUser(filter, pageSize, (currentPage - 1) * pageSize))
            handleTotalPagesByUser(filter)
        }
    }, [currentPage])

    useEffect(() => {
        setIsLoading(false)
    }, [consults])

    return !isLoading && !isEmpty(consults) ? (
        <div className={styles.body}>
            <div className={styles.title}>Todas las Consultas</div>

            <div className="flex justify-between p-5 text-black bg-white">
                <p>Total de registros: {allRecords}</p>
                <div className="flex gap-3">
                    <p className="">Filtrar:</p>
                    <select
                        name="filter"
                        id="filter"
                        value={filter}
                        className="py-1 pr-5 border-2 rounded-md border-violetDark focus-visible:outline-none"
                        onChange={(e) => handleSelect(e)}
                    >
                        <option value="all" className="">
                            Todos los registros
                        </option>
                        {users?.map((element: any, index: number) => (
                            <option value={element.username} className="" key={index}>
                                {element.username}
                            </option>
                        ))}
                    </select>
                </div>
                <button
                    className="px-5 text-sm text-white rounded-md bg-violetDark font-montserrat hover:scale-110"
                    onClick={() => recargar()}
                >
                    Actualizar
                </button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Nº</th>
                        <th>Usuario</th>
                        <th>Fecha</th>
                        <th>Tipo</th>
                        <th>Cliente</th>
                        <th>Consulta</th>
                        <th>Cartas</th>
                        <th>Respuesta</th>
                        <th>Consultar</th>
                    </tr>
                </thead>
                <tbody>
                    {consults?.map((consult: any, index: number) => {
                        return (
                            <tr className={index % 2 === 0 ? styles.gris : styles.white} key={index}>
                                <td>{allRecords - skip - index}</td>
                                <td className="px-5 font-semibold">{consult.user}</td>
                                <td>
                                    <div>{new Date(consult.createdAt).toLocaleDateString()}</div>
                                    <div>{new Date(consult.createdAt).toLocaleTimeString()}</div>
                                </td>
                                <td>{consult.type}</td>
                                <td>{consult.clientName}</td>
                                <td>{resumen(consult.clientAnswer)}</td>
                                <td>
                                    {consult.cards.map((card: any, index: number) => {
                                        return index < 2 && <div className={styles.nowrap}>{card.name}</div>
                                    })}
                                </td>
                                <td>
                                    <p>{resumen(consult.response)}</p>
                                </td>
                                <td className="flex items-center justify-center h-full">
                                    <Link to={`/user/consults-view/${consult.objectId}`}>
                                        <img
                                            src={require('../../../assets/images/view.png')}
                                            alt=""
                                            className={styles.icon}
                                        />
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="flex flex-wrap justify-center gap-2 mt-5">
                <button
                    className="px-3 py-2 text-sm text-white rounded-md bg-violetDark font-montserrat disabled:bg-slate-300 disabled:text-slate-400"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Anterior
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                    <button
                        className={`py-2 px-3 border-2 border-violetDark text-sm text-white ${
                            i + 1 === currentPage ? 'bg-violetDark' : ''
                        }`}
                        key={i}
                        onClick={() => setCurrentPage(i + 1)}
                    >
                        {i + 1}
                    </button>
                ))}
                <button
                    className="px-3 py-2 text-sm text-white rounded-md bg-violetDark font-montserrat disabled:bg-slate-300 disabled:text-slate-400"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Siguiente
                </button>
            </div>
        </div>
    ) : isLoading ? (
        <div className="flex items-center justify-center w-full gap-5 mt-20">
            <div className={styles.spinner}></div>
            <span className="text-2xl font-bold">Recuperando datos...</span>
        </div>
    ) : (
        <div className="flex items-center justify-center w-full gap-5 mt-20">
            <button
                className="flex items-center gap-3 px-5 py-1 text-lg text-white rounded-md bg-violetDark hover:scale-110"
                onClick={() => back()}
            >
                <KeyboardBackspaceIcon />
                Volver
            </button>
            <p className="text-xl font-semibold text-gray-600">No existen consultas guardadas</p>
        </div>
    )
}

export default AllConsults
