import { AllCardsType } from '../../../data/all-cards'
import { DataType } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataType, user: string, type: string) => {
    let consult
    if (data.tipo === '1') {
        consult = `Interpreta la tirada de cartas para la lectura del amor que realizaste para ${
            data.consultante.name
        }, las cartas que salieron son: ${cards[0].name}${cards[0].position === 'invertida' ? ' invertida' : ''}, ${
            cards[1].name
        }${cards[1].position === 'invertida' ? ' invertida' : ''}, ${cards[2].name}${
            cards[2].position === 'invertida' ? ' invertida' : ''
        }, ${cards[3].name}${cards[3].position === 'invertida' ? ' invertida' : ''}, ${cards[4].name}${
            cards[4].position === 'invertida' ? ' invertida' : ''
        }, ${cards[5].name}${cards[5].position === 'invertida' ? ' invertida' : ''}, ${cards[6].name}${
            cards[6].position === 'invertida' ? ' invertida' : ''
        }, ${cards[7].name}${cards[7].position === 'invertida' ? ' invertida' : ''}, ${cards[8].name}${
            cards[8].position === 'invertida' ? ' invertida' : ''
        } y ${cards[9].name}${cards[9].position === 'invertida' ? ' invertida' : ''}. ${
            data.consulta.length === 0
                ? 'Como tarotista experta que eres, decide tú como interpretarás estas cartas para hacer esta lectura general del amor.'
                : 'Una parte de las cartas, decide tú cuantas, las utilizarás para responder concretamente las siguientes inquietudes del consultante: ' +
                  data.consulta +
                  '; con el resto de las cartas interpreta una visión general de la vida amorosa del consultante. Como tarotista experta que eres, decide tú cual es la mejor forma de leer esta tirada.'
        }. Puedes usar combinación de cartas y ten en cuenta las influencias de las cartas, unas sobre otras. También, si consideras relevante, utliza en la tirada la influencia del signo zodiacal del consultante: ${
            data.consultante.zodiac
        }, si no lo crees relevante, ni lo menciones. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona persona. Incluye después de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante.`
    } else if (data.tipo === '2') {
        consult = `Interpreta la tirada de cartas para la lectura del vínculo que realizaste para ${
            data.consultante.name
        } como consultante y ${data.consultado.name} como consultado, las cartas que salieron son: ${cards[0].name}${
            cards[0].position === 'invertida' ? ' invertida' : ''
        }, ${cards[1].name}${cards[1].position === 'invertida' ? ' invertida' : ''}, ${cards[2].name}${
            cards[2].position === 'invertida' ? ' invertida' : ''
        }, ${cards[3].name}${cards[3].position === 'invertida' ? ' invertida' : ''}, ${cards[4].name}${
            cards[4].position === 'invertida' ? ' invertida' : ''
        }, ${cards[5].name}${cards[5].position === 'invertida' ? ' invertida' : ''}, ${cards[6].name}${
            cards[6].position === 'invertida' ? ' invertida' : ''
        }, ${cards[7].name}${cards[7].position === 'invertida' ? ' invertida' : ''}, ${cards[8].name}${
            cards[8].position === 'invertida' ? ' invertida' : ''
        } y ${cards[9].name}${cards[9].position === 'invertida' ? ' invertida' : ''}. ${
            data.consulta.length === 0
                ? 'Como tarotista experta que eres, decide tú como interpretarás estas cartas para hacer esta lectura general del vínculo.'
                : 'Una parte de las cartas, decide tú cuantas, las utilizarás para responder concretamente las siguientes inquietudes del consultante: ' +
                  data.consulta +
                  '; con el resto de las cartas interpreta una visión general del vínculo para estas dos personas. Como tarotista experta que eres, decide tú cual es la mejor forma de leer esta tirada.'
        }. Puedes usar combinación de cartas y ten en cuenta las influencias de las cartas, unas sobre otras. También, si consideras relevante, utliza en la tirada la influencia del signo zodiacal del consultante: ${
            data.consultante.zodiac
        }${
            data.consultado.zodiac.length > 0 ? ' y del consultado ' + data.consultado.zodiac + ',' : ','
        } si no lo crees relevante, ni lo menciones. Haz tus respuestas lo más extensas y detalladas posible. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante.`
    } else if (data.tipo === '3') {
        consult = `Por favor, realiza la interpretación de la tirada del amor en el tarot para ${
            data.consultante.name
        } de signo ${data.consultante.zodiac} ${data.consultado.name.length > 0 ? ' y ' + data.consultado.name : ''} ${
            data.consultado.zodiac.length > 0 ? ' de signo ' + data.consultado.zodiac : ''
        } como consultado con las cartas: ${cards[0].name}${cards[0].position === 'invertida' ? ' invertida' : ''}, ${
            cards[1].name
        }${cards[1].position === 'invertida' ? ' invertida' : ''}, ${cards[2].name}${
            cards[2].position === 'invertida' ? ' invertida' : ''
        }, ${cards[3].name}${cards[3].position === 'invertida' ? ' invertida' : ''}, ${cards[4].name}${
            cards[4].position === 'invertida' ? ' invertida' : ''
        }, ${cards[5].name}${cards[5].position === 'invertida' ? ' invertida' : ''}, ${cards[6].name}${
            cards[6].position === 'invertida' ? ' invertida' : ''
        }, ${cards[7].name}${cards[7].position === 'invertida' ? ' invertida' : ''}, ${cards[8].name}${
            cards[8].position === 'invertida' ? ' invertida' : ''
        } y ${cards[9].name}${
            cards[9].position === 'invertida' ? ' invertida' : ''
        }, en donde se interpreta en el mismo orden de las cartas al consultante, ${
            data.consultado.name.length > 0
                ? 'a la persona que motiva la consulta o consultado'
                : 'las influencias externas'
        }, los obstáculos y desafíos, las fortalezas y oportunidades y el futuro de la relación. ${
            data.consulta.length > 0
                ? 'Ten en cuenta en esta tirada la consulta realizada por el consultante: ' +
                  data.consulta +
                  ', y utiliza las 4 últimas cartas de esta tirada para responder concretamente sus inquietudes'
                : 'Utiliza las 4 últimas cartas para ofrecer consejos para mejorar en lo que haga la persona o pareja necesite'
        }. Ten en cuenta si lo crees relevante los signos zodiacales de los involucrados en la tirada${
            data.consultado.zodiac.length > 0
                ? ' y ten en cuenta tambien la compatibilidad en el amor de estas personas según el zodíaco si el consultante proporcionó el signo de ambos. Si no crees relevante el tema del zodíaco, ni lo menciones. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona.'
                : '. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante.'
        }`
    } else if (data.tipo === '4') {
        consult = `Por favor, realiza la intepretación de la *** Tirada del Amor *** de 10 cartas que realizaste para ${
            data.consultante
        }, que es una persona soltera o sin pareja, de la siguiente forma: 1. Energía actual en el amor: ${
            cards[0].name
        }${cards[0].position === 'invertida' ? ' invertida' : ''}, 2. Bloqueos emocionales que impiden el amor: ${
            cards[1].name
        }${cards[1].position === 'invertida' ? ' invertida' : ''}, 3. Cualidades atractivas del consultante: ${
            cards[2].name
        }${cards[2].position === 'invertida' ? ' invertida' : ''}, 4. Patrón de relaciones pasadas que debe sanar: ${
            cards[3].name
        }${cards[3].position === 'invertida' ? ' invertida' : ''}, 5. Consejo sobre cómo abrirse al amor: ${
            cards[4].name
        }${
            cards[4].position === 'invertida' ? ' invertida' : ''
        }, 6. Posibilidades de conocer a alguien en el corto plazo: ${cards[5].name}${
            cards[5].position === 'invertida' ? ' invertida' : ''
        }, 7. Cómo reconocer a la persona indicada: ${cards[6].name}${
            cards[6].position === 'invertida' ? ' invertida' : ''
        }, 8. Obstáculo o advertencia en el camino: ${cards[7].name}${
            cards[7].position === 'invertida' ? ' invertida' : ''
        }, 9. Hacia donde se dirige la situación amorosa: ${cards[8].name}${
            cards[8].position === 'invertida' ? ' invertida' : ''
        } y 10. Resultado a mediano plazo: ${cards[9].name}${cards[9].position === 'invertida' ? ' invertida' : ''}. ${
            data.consulta.length > 0
                ? 'Responde las inquietudes del consultate usando exclusivamente las cartas extraídas: ' + data.consulta
                : ''
        } Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante. No utilices emoticones. No pongas títulos, solo haz una narrativa de cada aspecto de las 10 cartas.`
    } else if (data.tipo === '5') {
        consult = `Por favor, realiza la intepretación de la *** Tirada del Amor *** de 10 cartas que realizaste para ${
            data.consultante.name
        }, que es una persona que se está conociendo con ${
            data.consultado.name
        }, de la siguiente forma: 1. Energía actual entre el consultante y la otra persona: ${cards[0].name}${
            cards[0].position === 'invertida' ? ' invertida' : ''
        }, 2. Cómo percibe la otra persona al consultante: ${cards[1].name}${
            cards[1].position === 'invertida' ? ' invertida' : ''
        }, 3. Las verdaderas intenciones de la otra persona: ${cards[2].name}${
            cards[2].position === 'invertida' ? ' invertida' : ''
        }, 4. Lo positivo de esta conexión: ${cards[3].name}${
            cards[3].position === 'invertida' ? ' invertida' : ''
        }, 5. Lo negativo o desafío en la conexión: ${cards[4].name}${
            cards[4].position === 'invertida' ? ' invertida' : ''
        }, 6. Qué debe hacer el consultante para fortalecer la conexión: ${cards[5].name}${
            cards[5].position === 'invertida' ? ' invertida' : ''
        }, 7. Factores externos que pueden influir en la relación: ${cards[6].name}${
            cards[6].position === 'invertida' ? ' invertida' : ''
        }, 8. Posibles obstáculos o advertencias: ${cards[7].name}${
            cards[7].position === 'invertida' ? ' invertida' : ''
        }, 9. Hacia dónde se dirige esta conexión a corto plazo: ${cards[8].name}${
            cards[8].position === 'invertida' ? ' invertida' : ''
        } y 10. Resultado probable a mediano plazo: ${cards[9].name}${
            cards[9].position === 'invertida' ? ' invertida' : ''
        }. ${
            data.consulta.length > 0
                ? 'Responde las inquietudes del consultate usando exclusivamente las cartas extraídas: ' + data.consulta
                : ''
        } Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante. No utilices emoticones. No pongas títulos, solo haz una narrativa de cada aspecto de las 10 cartas.`
    } else {
        consult = `Por favor, realiza la intepretación de la *** Tirada del Amor *** de 10 cartas que realizaste para ${
            data.consultante
        }, que es una persona que está en pareja con ${
            data.consultado
        }, de la siguiente forma: 1. Energía actual de la relación: ${cards[0].name}${
            cards[0].position === 'invertida' ? ' invertida' : ''
        }, 2. Energía de la pareja (cómo está emocionalmente): ${cards[1].name}${
            cards[1].position === 'invertida' ? ' invertida' : ''
        }, 3. Lo positivo de la relación: ${cards[2].name}${
            cards[2].position === 'invertida' ? ' invertida' : ''
        }, 4. Aspecto a mejorar o trabajar en la relación: ${cards[3].name}${
            cards[3].position === 'invertida' ? ' invertida' : ''
        }, 5. Consejo para fortalecer la relación: ${cards[4].name}${
            cards[4].position === 'invertida' ? ' invertida' : ''
        }, 6. Posibles conflictos o tensiones ocultas: ${cards[5].name}${
            cards[5].position === 'invertida' ? ' invertida' : ''
        }, 7. Hacia dónde se dirige la relación a corto plazo: ${cards[6].name}${
            cards[6].position === 'invertida' ? ' invertida' : ''
        }, 8. Obstáculo o advertencia en la relación: ${cards[7].name}${
            cards[7].position === 'invertida' ? ' invertida' : ''
        }, 9. Factores externos que afectan la relación: ${cards[8].name}${
            cards[8].position === 'invertida' ? ' invertida' : ''
        } y 10. Resultado a mediano plazo: ${cards[9].name}${cards[9].position === 'invertida' ? ' invertida' : ''}. ${
            data.consulta.length > 0
                ? 'Responde las inquietudes del consultate usando exclusivamente las cartas extraídas: ' + data.consulta
                : ''
        } Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante. No utilices emoticones. No pongas títulos, solo haz una narrativa de cada aspecto de las 10 cartas.`
    }

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
