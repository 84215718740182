import { AllCardsType } from '../../../data/all-cards'
import { DataTypePregunta } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataTypePregunta, user: string, type: string) => {
    //console.log('Recibido en interpret:', data)
    const consult = `Quiero que interpretes una **Tirada de Atracción Magnética** con 10 cartas. Esta tirada revela qué tipo de energía está emitiendo el consultante ${
        data.consultante
    } y cómo mejorar su vibración para atraer mejores oportunidades en el amor, el trabajo o la vida en general ${
        data.consulta.length > 0
            ? 'ten en cuenta en la tirada la siguiente consulta que realiza el consultante: ' + data.consulta + ','
            : ''
    } según estas cartas: **Energía actual del consultante:** ${cards[0].name}}${
        cards[0].position === 'invertida' ? ' invertida' : ''
    }, **¿Qué tipo de personas o situaciones está atrayendo?:** ${cards[1].name}}${
        cards[1].position === 'invertida' ? ' invertida' : ''
    }, **¿Por qué está atrayendo esto?:** ${cards[2].name}${
        cards[2].position === 'invertida' ? ' invertida' : ''
    }, **¿Cómo está afectando su vida esta energía?:** ${cards[3].name}${
        cards[3].position === 'invertida' ? ' invertida' : ''
    }, **¿Qué puede hacer para mejorar su vibración y atraer lo que realmente desea?:** ${cards[4].name}${
        cards[4].position === 'invertida' ? ' invertida' : ''
    }, **Energía que necesita reforzar para manifestar cosas positivas:** ${cards[5].name}${
        cards[5].position === 'invertida' ? ' invertida' : ''
    }, **Energía que debe eliminar o dejar atrás para evitar atraer lo negativo:** ${cards[6].name}${
        cards[6].position === 'invertida' ? ' invertida' : ''
    } **Cómo potenciar su magnetismo personal:**: ${cards[7].name}${
        cards[7].position === 'invertida' ? ' invertida' : ''
    } **Consejo del tarot para ajustar su energía de atracción:** ${cards[8].name}${
        cards[8].position === 'invertida' ? ' invertida' : ''
    } y **Resultado si trabaja en su vibración:** ${cards[9].name}${
        cards[9].position === 'invertida' ? ' invertida' : ''
    }. Explica detalladamente cada carta y su significado en la posición donde ha salido y detecta patrones o bloqueos energéticos. Da consejos prácticos para mejorar la vibración del consultante y atraer lo que realmente desea. Si notas bloqueos emocionales o energéticos, sugiere ejercicios o cambios en su mentalidad. Usa un tono motivador, claro y empático. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación de cada una de todo el desarrollo de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante en esta tirada.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
