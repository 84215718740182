import { AllCardsType } from '../../../data/all-cards'
import { DataTypePregunta } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataTypePregunta, user: string, type: string) => {
    let consult = `Por favor, realiza la interpretacion de la Tirada de Tarot Evolutivo, lo mas detallada y extensa posible pero en un lenguaje práctico y fácil de entender, para ${
        data.consultante.name
    } de signo zodiacal ${
        data.consultante.zodiac
    }, ten en cuenta en esta tirada su signo zodiacal y su personalidad número ${
        data.consultante.personality
    } según la numerología, ${
        data.consulta.length > 0 ? ' y adapta la tirada al siguiente aspecto: ' + data.consulta : ''
    } y organiza la tirada de la siguiente forma: 1) Tirada del Camino de la Vida: ${cards[0].name}${
        cards[0].position === 'invertida' ? ' invertida' : ''
    } en el pasado (eventos, patrones o influencias del pasado que han moldeado al consultante y que siguen afectándolo), ${
        cards[1].name
    }${
        cards[1].position === 'invertida' ? ' invertida' : ''
    } en el presente (situación actual del consultante, su estado mental, emocional o espiritual, y los factores que están en juego ahora mismo), ${
        cards[2].name
    }${
        cards[2].position === 'invertida' ? ' invertida' : ''
    } en los desafíos que debe enfrentar para avanzar en el camino de la vida, ${cards[3].name}${
        cards[3].position === 'invertida' ? ' invertida' : ''
    } en las oportunidades para superar desafíos y avanzar en su evolución, ${cards[4].name}${
        cards[4].position === 'invertida' ? ' invertida' : ''
    } en la lección de vida a aprender para evolucionar y avanzar y ${cards[5].name}${
        cards[5].position === 'invertida' ? ' invertida' : ''
    } en el potencial futuro si el consultante sigue el camino sugerido, incorporando las lecciones y aprovechando las oportunidades; 2) Tirada de las Lecciones de Vida: ${
        cards[6].name
    }${
        cards[6].position === 'invertida' ? ' invertida' : ''
    } en la lección actual que el consultante está enfrentando, ${cards[7].name}${
        cards[7].position === 'invertida' ? ' invertida' : ''
    } en los bloqueos, ${cards[8].name}${
        cards[8].position === 'invertida' ? ' invertida' : ''
    } en los recursos internos, ${cards[9].name}${
        cards[9].position === 'invertida' ? ' invertida' : ''
    } en las influencias externas y ${cards[10].name}${
        cards[10].position === 'invertida' ? ' invertida' : ''
    } en el siguiente paso evolutivo; y 3) Tirada del Ser Superior: ${cards[11].name}${
        cards[11].position === 'invertida' ? ' invertida' : ''
    } en el Yo inferior, ${cards[12].name}${
        cards[12].position === 'invertida' ? ' invertida' : ''
    } en el Yo consciente y ${cards[13].name}${
        cards[13].position === 'invertida' ? ' invertida' : ''
    } en el Yo superior. Luego de las interpretaciones, ofrece un resúmen con título del Camino de la vida, otro resúmen con título de las Lecciones de Vida y otro resúmen con título del Ser Superior. Al final ofrece recomendaciones para lograr los objetivos y el premio por evolucionar. Dirígete directamente al consultante, no utilices la tercera persona refiriendote al consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Utiliza la narrativa, no hagas listas numeradas. Por favor, no saludes, se supone que ya estoy teniendo un conversación con el consultante, tampoco te despidas por si el consultante quiere seguir contratando preguntas. No olvides los signos de puntuación, comas, puntos, etc. en los resúmenes.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
