import React from 'react'
import { AllCardsType } from '../../../data/all-cards'

export const ShowCards = ({ selectedCards }: { selectedCards: AllCardsType[] }) => {
    return (
        <div className="flex flex-col items-center w-full h-full mini:px-2 md:px-5">
            <div className="flex flex-wrap justify-center gap-3 mini:pt-10 xl:pt-0">
                {selectedCards.map((card: AllCardsType, index: number) => (
                    <div className="flex flex-col gap-2">
                        <img
                            src={require('../../../assets/cards/' + card.url + '/' + card.image)}
                            className={`mini:w-[17vw] xl:w-[8.38vw] mini:h-auto xl:h-auto rounded-md ${
                                card.position === 'invertida' ? 'rotate-180' : ''
                            }`}
                            alt=""
                        />
                        <div className="mini:w-[17vw] xl:w-[8.38vw] bg-violetLight mini:text-sm xl:text-base text-center rounded-md">
                            {card.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
