import { AllCardsType } from '../../../data/all-cards'
import { DataTypePregunta } from '../../../interfaces/utils'

export const interpret = (cards: AllCardsType[], data: DataTypePregunta, user: string, type: string) => {
    //console.log('Recibido en interpret:', data)
    const consult = `Quiero que interpretes una **Tirada de Abundancia y Prosperidad** con 10 cartas, analizando el estaro financiero y energético del consultante ${
        data.consultante
    }, ${
        data.consulta.length > 0
            ? 'ten en cuenta en la tirada la siguiente consulta que realiza el consultante: ' + data.consulta + ','
            : ''
    } según estas cartas: **Energía actual respecto a la abundancia:** ${cards[0].name}}${
        cards[0].position === 'invertida' ? ' invertida' : ''
    }, **Bloqueo o creencia limitante:** ${cards[1].name}}${
        cards[1].position === 'invertida' ? ' invertida' : ''
    }, **Origen del bloqueo:** ${cards[2].name}${
        cards[2].position === 'invertida' ? ' invertida' : ''
    }, **Recursos ocultos o talentos desaprovechados:** ${cards[3].name}${
        cards[3].position === 'invertida' ? ' invertida' : ''
    }, **Oportunidades en camino:** ${cards[4].name}${
        cards[4].position === 'invertida' ? ' invertida' : ''
    }, **Acción clave para atraer prosperidad:** ${cards[5].name}${
        cards[5].position === 'invertida' ? ' invertida' : ''
    }, **Cómo gestionar mejor sus finanzas:** ${cards[6].name}${
        cards[6].position === 'invertida' ? ' invertida' : ''
    } **Consejo del universo:**: ${cards[7].name}${
        cards[7].position === 'invertida' ? ' invertida' : ''
    } **Posibles obstáculos futuros:** ${cards[8].name}${
        cards[8].position === 'invertida' ? ' invertida' : ''
    } y **Resultado final si se sigue el consejo:** ${cards[9].name}${
        cards[9].position === 'invertida' ? ' invertida' : ''
    }. Explica detalladamente cada carta y su significado dentro de la tirada. Luego, brinda consejos específicos para para atraer abundancia y prosperidad en la vida del consultate basados en la interpretación. Mantén un tono profesional y comprensible. Dirígete directamente al consultante, no utilices la tercera persona refiriendote a la consultante, aún cuando las consultas se encuentren en tercera persona, adapta la respuesta para que quede en segunda persona. Incluye después de la interpretación de cada una de todo el desarrollo de la interpretación, en un nuevo párrafo, un resúmen que aclare concretamente lo que las cartas le quieren transmitir al consultante en esta tirada.`

    let info = {
        consult: consult,
        user: user,
        clientName: data.consultante.name,
        clientAnswer: data.consulta,
        cards: cards,
        type: type,
    }

    return info
}
